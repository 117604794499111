import React, { useEffect } from 'react';
import ReactGA, { FieldsObject } from 'react-ga';
import { useLocation } from 'react-router-dom';

ReactGA.initialize('UA-74652209-8');

export const withTracker = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {}
) => {
  return (props: P) => {
    const location = useLocation();

    useEffect(() => {
      ReactGA.set({ page: location.pathname, ...options });
      ReactGA.pageview(location.pathname);
    }, [location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
