import React from 'react';
import { useParams } from 'react-router-dom';
import DrawingContainer from '../components/DrawingContainer';

const Viewer: React.FC = () => {
  const { uuid = '' } = useParams<{ uuid: string }>();

  return (
    <>
      <DrawingContainer uuid={uuid} />
    </>
  );
};

export default Viewer;
