import React, { Component } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import QRCode from 'qrcode';

type InputProps = {
  uuid: string;
};

export default class ShareModal extends Component<
  InputProps,
  { show: boolean }
> {
  private el: HTMLCanvasElement | null = null;
  private urlField: HTMLInputElement | null = null;
  private appLink: string;

  constructor(props: InputProps) {
    super(props);
    this.state = {
      show: false,
    };
    this.appLink = `iqdarapp://view?uuid=${this.props.uuid}`;
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  createCode = () => {
    if (this.el) {
      QRCode.toCanvas(this.el, this.appLink);
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleCopyUrl = () => {
    if (this.urlField) {
      this.urlField.select();
      // copy to clipboard
      document.execCommand('copy');
      this.urlField.focus();
    }
  };

  handleOpenInAR = () => {
    window.open(this.appLink, '_blank');
  };

  render() {
    return (
      <>
        <Button variant='info' onClick={this.handleShow}>
          Share
        </Button>
        <Modal
          show={this.state.show}
          onEntered={this.createCode}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <strong>URL</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup className='mb-3'>
                  <FormControl
                    readOnly
                    value={window.location.href}
                    ref={(input: any) => (this.urlField = input)}
                    onClick={this.handleCopyUrl}
                  />
                  <Button
                    variant='outline-secondary'
                    onClick={this.handleCopyUrl}
                  >
                    Copy
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <strong>Augmented Reality</strong>
                <br />
                <small className='text-muted'>
                  Tap the button or scan the QR code below to view this 3D model in iQD AR.
                </small>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className='align-self-center' variant='primary' onClick={this.handleOpenInAR}>
                  Open in AR <FontAwesomeIcon icon={faExpand} />
                </Button>
              </Col>
              <Col>
                <canvas ref={(element) => (this.el = element)}></canvas>
              </Col>
            </Row> */}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
