import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Container, Navbar } from 'react-bootstrap';
import { format } from 'date-fns';
import './App.css';
import logo from './hi-level_logo.png';
import Home from './containers/Home';
import Viewer from './containers/Viewer';
import packageJson from '../package.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withTracker } from './withTracker';

const version = packageJson.version;

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
  name: '3D Viewer',
  version,
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className='App'>
          <Container fluid>
            <Navbar bg='light' expand='lg' sticky='top'>
              <Navbar.Brand>
                <img
                  alt='Hi-Level Logo'
                  src={logo}
                  height='30'
                  className='d-inline-block align-top'
                />{' '}
                iQD 3D Viewer
              </Navbar.Brand>
            </Navbar>
            <Routes>
              <Route path='/' element={<WithTrackingHome />} />
              <Route path='/:uuid' element={<WithTrackingViewer />} />
            </Routes>
          </Container>
        </div>
        <footer className='footer fixed-bottom'>
          <small className='text-muted'>
            &copy; 2016 - {format(new Date(), 'yyyy')}, The Mezzanine Floor
            Group Ltd. All rights reserved.{' '}
            <a
              href='https://www.hi-levelmezzanines.co.uk/privacy-policy/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
          </small>
        </footer>
      </Router>
    </ApolloProvider>
  );
};

const WithTrackingHome = () => withTracker(Home)({});
const WithTrackingViewer = () => withTracker(Viewer)({});

export default App;
