import React from 'react';
import { useQuery, gql } from '@apollo/client';
import ThreeContainer from './ThreeContainer';
import { Drawing } from '@hi-level/3d-renderer';

const QUOTE_OPTION_QUERY = gql`
  query GetDrawing($uuid: ID!) {
    quoteOptionByUuid(uuid: $uuid) {
      drawing {
        Floor {
          Size {
            XDim
            YDim
            TotalZDim
          }
          Tiers {
            Number
            Size {
              XDim
              YDim
              ZDim
            }
            Columns {
              Id
              Position {
                X
                Y
              }
              IsFlagpost
              IsGoalpost
              IsTagged
              Connections {
                Capplates {
                  Id
                }
                Beams {
                  Id
                }
              }
            }
            Beams {
              Id
              ConstructionType
              Start {
                X
                Y
              }
              End {
                X
                Y
              }
            }
            Joists {
              Start {
                X
                Y
              }
              End {
                X
                Y
              }
              Connections {
                Beams {
                  Id
                }
                Columns {
                  Id
                }
              }
            }
            Angles {
              CutoutDirection
              Position {
                X
                Y
              }
              Start {
                X
                Y
              }
              End {
                X
                Y
              }
            }
            Cutouts {
              Size {
                XDim
                YDim
              }
              Position {
                X
                Y
              }
            }
          }
          BayDirection
        }
      }
    }
  }
`;

type Props = {
  uuid: string;
};

const DrawingContainer: React.FC<Props> = ({ uuid }) => {
  const { loading, error, data } = useQuery(QUOTE_OPTION_QUERY, {
    variables: { uuid },
  });

  if (loading) return <div>Loading...</div>;
  if (error || !data?.quoteOptionByUuid) return <h1>error</h1>;

  return (
    <ThreeContainer
      drawing={data.quoteOptionByUuid.drawing as Drawing}
      uuid={uuid}
    />
  );
};

export default DrawingContainer;
